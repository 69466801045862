<script lang="ts" setup>
  import type {WP} from "~/integration/wordpress/types";
  import Marker from "~/components/Redmonkey/Base/Marker.vue";

  const ignore = ['Без категорії'];

  const filterList = ref([] as []);

  const props = defineProps({
    type: {
      type: String,
      default: ''
    },
    fetch: {
      type: Function,
      default: (name: string) => {}
    },
    activeName: {
      type: String,
      default: ''
    }
  })

  const {$vwf} = useNuxtApp();

  const {data: categories} = await useAsyncData(`filter-${props.type}`, async () => {
    const {categories} = await ($vwf as WP).$wordpress.api.getCategoryFilter();
    return categories;
  });

  if(categories.value?.nodes) {
    const list = [];

    for(let c = 0; c < categories.value.nodes.length; c++) {
      if(!ignore.includes(categories.value.nodes[c].name) && categories.value.nodes[c]?.[props.type]?.nodes?.length > 0 ) {
        list.push(categories.value.nodes[c]);
      }
    }

    filterList.value = list as [];
  }
</script>
<template>
  <div class="category-filter" v-if="filterList.length">
    <div class="filter-items-wrapper flex wrap middle">
      <div class="filter-items-caption">
        <p>Оберіть категорію:</p>
      </div>
      <div class="filter-items flex wrap">
        <div class="filter-item" :class="{'_active': !activeName}">
          <Marker>
            <a href="#" @click.prevent="activeName !== '' ? fetch('') : () => {}">{{ `Всі ${type === 'courses' ? 'курси' : 'заходи'}` }}</a>
          </Marker>
        </div>
        <template v-for="item in filterList">
          <div class="filter-item" :class="{'_active': activeName === item.name}">
            <Marker>
              <a href="#" @click.prevent="activeName !== item.name ? fetch(item.name) : () => {}">{{item.name}}</a>
            </Marker>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import "assets/css/source/components/category_filter";
</style>